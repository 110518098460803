@import "/src/styles/mixins";
@import "/src/styles/pallete";

.textAreaLabel {
    width: 100%;
    position: relative;

    textarea {
        height: 100%;
        min-height: 89px!important;
        width: 100%;
        border: 1px solid #98a2b2;
        border-radius: 3px;
        padding: 4px 24px;
        color: var(--black);
        transition: 0.3s;
        min-height: 44px;
        background-color: var(--secondWhite);
        overflow-y: hidden;

        &:hover {
            border: 1px solid #379fff;
            box-shadow: 0px 4px 10px rgba(102, 118, 149, 0.2);
        }
        &:focus {
            border: 1px solid #379fff !important;
            + .placeholder {
                top: 0;
                transform: translateY(calc(-50% - 4px));
            }
        }
        &:required {
            + .placeholder {
                &::after {
                    content: "*";
                }
            }
        }
        &:disabled {
            background-color: var(--gray);
            border-color: transparent !important;
            + .placeholder {
                background-color: var(--gray);
            }
        }
    }
    &.filled {
        .placeholder {
            top: 0;
            transform: translateY(calc(-50% - 4px));
        }
    }
}
.placeholder {
    pointer-events: none;
    transition: 0.3s;
    @include absoluteCenter(vertical);
    top: 28%;
    left: 17px;
    background-color: var(--secondWhite);
    padding: 2px 7px;
    @include fontRem(14, 500, 22);
    display: flex;
    color: #98a2b2;
    z-index: 1;

    &::after {
        content: none;
        color: #ff5454;
        @include fontRem(12, 500, 20);
        margin-top: -5px;
        margin-left: 3px;
        display: block;
    }
}
