.container {
  width: 400px;
  height: 454px;
  overflow: hidden;
  padding: 24px;
  left: 50vw;
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -60%);
  background-color: #252733;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
    .modalHeaderWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      width: 100%;
      .name {
        color: #ffffff;
        background: #4A4C64;
        border-radius: 24px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .childrenWrapper  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .childrenTitle {
        font-size: 40px;
        font-weight: 900;
        color: #ffffff;
        margin-bottom: 24px;
      }
      .callUs {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        a {
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .orEmail {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        a {
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .call {
        width: 164px;
        height: 42px;
        background: #34A853;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
      }
      .sendEmail {
        width: 164px;
        height: 42px;
        background: #379FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .closeIcon {
      cursor: pointer;
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
}

.small {
  width: 90%;
  height: fit-content;
  gap: 40px;
    .modalHeaderWrapper {
      .name {
        font-size: 12px;
      }
    }
    .childrenWrapper  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .childrenTitle {
        font-size: 24px;
      }
    }
    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .call {
        width: 114px;
      }
      .sendEmail {
        width: 114px;
      }
    }
}

.lightMode {
    background-color: #FFFFFF;
      .modalHeaderWrapper {
        .name {
          color: #ffffff;
          background: #4A4C64;
        }
      }
      .childrenWrapper  {
        .childrenTitle {
          color: #252733;
        }
        .callUs {
          color: #252733;
          a {
            color: #252733;
          }
        }
        .orEmail {
          color: #252733;
          a {
            color: #252733;
          }
        }
      }
      .closeIcon {
        cursor: pointer;
        svg {
          path {
            fill: #252733;
          }
        }
  }
}
