.formWrapper {
  width: 100%;
  height: 100%;
  padding-top: 33px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .row {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 18px;
    height: 60px;
    div {
      flex: 1;
    }
  }
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
}

.cancel {
  width: 103px;
  height: 42px;
  background: none;
  border: none;
  color: #98A2B2;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.send {
  width: 103px;
  height: 42px;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  background: #34A853;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #3bb55b;
}
&:focus,
&:active {
    background-color: var(--primaryBtn);
}
}

.quoteText {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.truckTypes {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 88px;

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
    .truckType {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #252733;
      font-weight: 500;
    }
  }
}

.closeQuoteBlock {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 500;
  }
}