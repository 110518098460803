.btnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn {
  cursor: pointer;
  background-color: #34A853;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  border: none;
  border-radius: 6px;
  height: 42px;
  width: 100%;
}

.bigSuccess {
  height: 20px;
  max-height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #379FFF;
  color: #000;
  svg {
    path {
      fill: #ffffff;
    }
  }
}

.smallSuccess {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: #379FFF;
    }
  }
}

.bigItem {
  display: flex;
  gap: 6px;
  align-items: center;
}

.smallItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

.bigText {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.smallText {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.contentWrapper {
  display: grid;
  gap: 6px;
  padding: 13px 12px;
  background: #fff;
  &.lightMode {
    background: #f5f5f5;
  }
}

.smallTextWrapper {
  display: grid;
  gap: 3px;
  padding-left: 29px;
}