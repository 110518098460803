.formWrapper {
  width: 100%;
  height: 100%;
  padding-top: 33px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .row {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 18px;
    height: 60px;
    div {
      flex: 1;
    }
  }
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.cancel {
  width: 103px;
  height: 42px;
  background: none;
  border: none;
  color: #98A2B2;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.send {
  width: 103px;
  height: 42px;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  background: #34A853;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #3bb55b;
}
&:focus,
&:active {
    background-color: var(--primaryBtn);
}
}