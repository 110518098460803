.smallContainer {
  width: 400px;
  max-height: 822px;
  padding: 24px;
  left: 50vw;
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -50%);
  background-color: #252733;
  border-radius: 16px;
  &.light {
    background-color: #ffffff;
    .name {
      color: #252733;
    }
  }
  z-index: 2;
    .modalHeaderWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .childrenWrapper {
      padding: 0 13px;
    }
    .closeIcon {
      cursor: pointer;
      svg {
        width: 12px;
        path {
          fill: #B3B3B3;
        }
      }
    }
    .name {
      color: #ffffff;
      font-size: 40px;
      font-weight: 900;
    }
}

.largeContainer {
  width: 716px;
  height: 660px;
  max-height: 660px;
  overflow: auto;
  padding: 30px 51px 54px 51px;
  left: 50vw;
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -50%);
  background-color: white;
  scroll-behavior: smooth;
  border-radius: 3px;
    .modalHeaderWrapper {
      display: flex;
      justify-content: space-between;
      gap: 9px;
      align-items: center;
      padding-bottom: 8px;
    }
    .line {
      border: 1px #D9D9D9 solid;
    }
    .closeIcon {
      cursor: pointer;
    }
}



@media only screen and (max-width: 1366px) {
  .smallContainer {
    height: 400px!important;
    .childrenWrapper {
      overflow-y: auto!important;
      max-height: 350px!important;
    }
  }
  .largeContainer {
    height: 400px!important;
  }
}