@import './pallete.scss';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    overflow-wrap: break-word;
    hyphens: auto;
    margin: 0;
}
button,
input,
select,
label {
    font-family: inherit;
    outline: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
* {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html {
	font-family: "Inter", sans-serif !important;
}
body{
	min-height: 100vh;
    font-family: "Inter", sans-serif !important;
}
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Inter", sans-serif !important;
}
.container{
	max-width: 1230px;
	width: 100%;
	padding: 0 15px;
	margin:0 auto;
}

.circle__spinner {
    width: 100%;
    height: 100%;
	animation: rotate 1s linear infinite;
    & .path {
      animation: dash 0.2s linear alternate 1;
	  animation-delay: 0.2s;
	  stroke-dasharray: 0, 150;
	  stroke-dashoffset: 0;
	  animation-fill-mode:forwards;
    }
  }
[class*="ant-col"],
[class*="ant-typography"],
[class*="ant-tree-select"] {
    font-family: "Inter", sans-serif !important;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 110, 100;
    stroke-dashoffset: 0;
  }
}
.parent{
	overflow: hidden;
	max-height: 100vh;
	&.map-mode{
		overflow: initial;
		max-height: initial;
	}
}
#shape2{
	visibility: hidden;
}
#root{
	min-height: 100vh;
}
#my-svg path:hover {
	transform-origin: center center;
	animation: rotatePath 5s linear infinite;
}
