.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn {
  cursor: pointer;
  padding: 12px 40px;
  background-color: #34A853;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  border: none;
  border-radius: 6px;
  width: 145px;
}

.bigSuccess {
  height: 20px;
  max-height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #379FFF;
  color: #000;
  svg {
    path {
      fill: #ffffff;
    }
  }
}

.smallSuccess {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: #379FFF;
    }
  }
}

.bigItem {
  display: flex;
  gap: 6px;
  align-items: center;
}

.smallItem {
  display: flex;
  gap: 5px;
  align-items: center;
}

.bigText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  &.light {
    color: #252733;
  }
}

.smallText {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  &.light {
    color: #252733;
  }
}

.contentWrapper {
  display: grid;
  gap: 16px;
  padding: 13px 0;
}

.smallTextWrapper {
  display: grid;
  gap: 6px;
  padding-left: 30px;
}

@media only screen and (max-width: 1366px) {
  .contentWrapper {
    gap: 2px!important;
    padding: 3px 0!important;
    * {
      font-size: 8px;
    }
  }
  .btn {
    cursor: pointer;
    padding: 6px 12px;
    background-color: #34A853;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    line-height: 19px;
    border: none;
    border-radius: 6px;
    width: 145px;
  }
}