.largeContainer {
  width: 90vw;
  height: fit-content;
  max-height: calc(100vh - 60px);
  overflow: scroll;
  padding: 16px 16px 0 16px;
  left: 50vw;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  scroll-behavior: smooth;
  border-radius: 12px;
  z-index: 20000;
  ::-webkit-scrollbar {
    display: none;
  }
    .modalHeaderWrapper {
      display: flex;
      justify-content: space-between;
      gap: 9px;
      align-items: center;
      padding-bottom: 8px;
    }
    .line {
      border: 1px #D9D9D9 solid;
    }
    .closeIcon {
      cursor: pointer;
    }
}