.carComp {
  margin: 0;
  width: 100%;
  background: #252733;
  padding: 87px 0 0 0;
  opacity: 0;

  &.animatedStart {
    animation: opacityToShow 1s linear 1;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
  }

  &.lightMode {
    background-color: #fff;
  }

  .space-holder {
    position: relative;
    width: 100%;

    .sticky {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
      overflow-x: hidden;

      .horizontal {
        position: absolute;
        height: 100%;
        will-change: transform;
        overflow: hidden;
        margin: 0;
        padding: 0;
        background-color: #252733;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        &.lightMode {
          background-color: #fff;

          .background-overlay {
            & > svg {
              height: 106%;
            }
          }
        }

        svg {
          z-index: 1;
        }

        .background-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          .message-overlay {
            opacity: 0;
            max-width: 400px;
            width: 100%;
            position: absolute;
            min-height: 170px;
            display: flex;
            align-items: end;

            &.dark {
              .dashed-line, .dashed-line-right, .dashed-line-right-top, .dashed-line-right-dispatcher, .dashed-line-right-shipper, .dashed-line-right-company, .dashed-line-right-bottom {
                border-color: #252733;
              }

              .circle-animation, .circle-animation-right-top {
                svg {
                  circle {
                    stroke: #252733;;
                  }
                }
              }
            }

            &.show {
              animation: showMessage 0.2s;
              animation-fill-mode: forwards;

              .message {
                transform: scale(1);
                transition: 0.2s;
              }
            }

            .dashed-line {
              left: 185px;
              top: 172px;
              height: 0;
              width: 2px;
              border-left: 2px dashed #fff;
              position: absolute;
              animation: wayOne 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .dashed-line-right {
              width: 0;
              border-top: 2px dashed #fff;
              top: 421px;
              left: 187px;
              position: absolute;
              animation: wayTwo 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .circle-animation {
              position: absolute;
              left: 403px;
              top: 290px;
              width: 280px;
              height: 280px;

              .brand {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                animation: scaleAnimation 0.2s;
                animation-fill-mode: forwards;
                animation-delay: 0.2s;
              }
            }

            .dashed-line-right-top {
              transform: rotate(-84deg);
              left: 463px;
              top: 188px;
              bottom: -47px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayThree 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .circle-animation-right-top {
              width: 136px;
              height: 136px;
              position: absolute;
              left: 535px;
              top: -55px;

              &.right-bottom {
                left: 692px;
                top: 12px;
              }

              &.dispatcher-bottom {
                left: 831px;
                top: 112px;
              }

              &.shipper-bottom {
                left: 928px;
                top: 247px;
              }

              &.company-bottom {
                left: 948px;
                top: 399px;
              }


              .status {
                cursor: pointer;
                display: flex;
                text-align: center;
                transform: scale(0);
                position: absolute;
                left: 50%;
                top: 50%;
                background-color: #379FFF;
                border-radius: 50%;
                width: 98px;
                height: 98px;
                align-items: center;
                justify-content: center;
                animation: scale 0.2s linear alternate 1;
                animation-fill-mode: forwards;
                animation-delay: 0.2s;

                * {
                  font-size: 18px;
                  line-height: 21px;
                  color: #fff;
                  font-weight: 700;
                }
              }
            }

            .dashed-line-right-bottom {
              transform: rotate(-58deg);
              left: 552px;
              bottom: -56px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayFour 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .dashed-line-right-dispatcher {
              transform: rotate(-34deg);
              left: 618px;
              bottom: -111px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayFive 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .dashed-line-right-shipper{
              transform: rotate(-13deg);
              left: 658px;
              bottom: -191px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: waySix 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .dashed-line-right-company {
              transform: rotate(4deg);
              left: 667px;
              bottom: -281px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: waySeven 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .active-modal-Company {
              transform: rotate(147deg);
              left: 1036px;
              bottom: -162px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayCompany 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .active-modal-Shipper {
              transform: rotate(168deg);
              left: 1049px;
              bottom: -90px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayShipper 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .active-modal-Dispatcher {
              transform: rotate(185deg);
              left: 958px;
              bottom: -35px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayDispatcher 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .active-modal-Broker {
              transform: rotate(195deg);
              left: 810px;
              bottom: 30px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayBroker 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }

            .active-modal-Carrier{
              transform: rotate(180deg);
              left: 662px;
              bottom: 159px;
              width: 0;
              height: 2px;
              border-top: 2px dashed #fff;
              position: absolute;
              animation: wayCarrier 0.2s;
              animation-fill-mode: forwards;
              animation-delay: 0.2s;
            }
          }

          svg {
            height: 100%;
          }
        }
      }
    }
  }
}

.messageTwo-overlay {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 200px;
  visibility: hidden;

  &.show {
    visibility: visible;

    .message {
      transform: scale(1);
      transition: 0.2s;
    }
  }
}

.message {
  max-width: 400px;
  width: 100%;
  padding: 12px 14px;
  background-color: #fff;
  border-radius: 12px;
  position: absolute;
  text-align: left;
  transition: 0.2s;
  transform: scale(0);
  display: flex;
  flex-direction: column;
  gap: 20px;  
  filter: drop-shadow(0px 4px 12px rgba(37, 39, 51, 0.10));

  * {
    color: #252733;
    font-size: 25px;
    line-height: 30px;
  }

  &::after {
    content: "";
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 42px;
    height: 26px;
    background-color: #fff;
    position: absolute;
    bottom: -25px;
    left: 25px;
    transform: rotate(180deg);
  }
}

.messageText {
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  color: #252733;
}

.modal {
  left: 1395px;
  top: -89;
}

.youtube {
    position: absolute;
    animation: showMessage 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    z-index: 1;
    top: 142.526px;
    left: 186.82px;
    img {
      max-width: 900px;
      max-height: 500px;
    }
}

@keyframes opacityToShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showMessage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wayOne {
  0% {
    height: 0;
  }
  100% {
    height: 250px;
  }
}

@keyframes wayTwo {
  0% {
    width: 0;
  }
  100% {
    width: 230px;
  }
}

@keyframes wayFour {
  0% {
    width: 0;
  }
  100% {
    width: 228px;
  }
}

@keyframes wayFive {
  0% {
    width: 0;
  }
  100% {
    width: 252px;
  }
}

@keyframes waySix {
  0% {
    width: 0;
  }
  100% {
    width: 284px;
  }
}

@keyframes waySeven {
  0% {
    width: 0;
  }
  100% {
    width: 291px;
  }
}


@keyframes wayCompany {
  0% {
    width: 0;
  }
  100% {
    width: 400px;
  }
}

@keyframes wayShipper {
  0% {
    width: 0;
  }
  100% {
    width: 362px;
  }
}

@keyframes wayDispatcher {
  0% {
    width: 0;
  }
  100% {
    width: 450px;
  }
}

@keyframes wayBroker {
  0% {
    width: 0;
  }
  100% {
    width: 600px;
  }
}

@keyframes wayCarrier {
  0% {
    width: 0;
  }
  100% {
    width: 555px;
  }
}



@keyframes wayThree {
  0% {
    bottom: -100px;
    width: 0;
  }
  100% {
    bottom: -44px;
    width: 237px;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes dashNew {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 31, 101;
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 1366px) {
  .message {
    max-height: 130px;
    gap: 10px;
    p {
      font-size: 18px;
    }
  }
  .youtube {
    top: 144px;
    img {
      max-width: 475px;
      max-height: 279px;
    }
  }
  .dashed-line {
    top: 112px!important;
  }
  .dashed-line-right {
    top: 361px!important;
  }
  .circle-animation {
    top: 250px!important;
    width: 220px!important;
    height: 220px!important;
  }
  .circle-animation-right-top {
    width: 90px!important;
    height: 90px!important;
    position: absolute;
    left: 435px!important;
    top: 40px!important;

    &.right-bottom {
      left: 556px!important;
      top: 50px!important;
    }

    &.dispatcher-bottom {
      left: 668px!important;
      top: 95px!important;
    }

    &.shipper-bottom {
      left: 776px!important;
      top: 168px!important;
    }

    &.company-bottom {
      left: 858px!important;
      top: 259px!important;
    }


    .status {
      cursor: pointer;
      display: flex;
      text-align: center;
      transform: scale(0);
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #379FFF;
      border-radius: 50%;
      width: 68px!important;
      height: 68px!important;
      align-items: center;
      justify-content: center;
      animation: scale 0.2s linear alternate 1;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;

      * {
        font-size: 12px!important;
        line-height: 21px;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .dashed-line-right-top {
    transform: rotate(-96deg)!important;
    top: 164px!important;
    max-width: 217px!important;
    left: 379px!important;
  }
  .dashed-line-right-bottom {
    transform: rotate(-81deg) !important;
    left: 474px!important;
    bottom: -6px!important;
  }

  .dashed-line-right-dispatcher {
    transform: rotate(-61deg)!important;
    left: 531px!important;
    bottom: -79px!important;
  }

  .dashed-line-right-shipper{
    transform: rotate(-32deg) !important;
    left: 581px!important;
    bottom: -116px!important;
  }

  .dashed-line-right-company {
    transform: rotate(-15deg) !important;
    left: 588px!important;
    bottom: -182px!important;
  }

  .messageTwo-overlay {
    top: 15px!important;
  }

}

.vendorsWrapper {
  position: absolute;
  transform: translateY(-50%);
  width: 1313px;
  height: 600px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 16ppx;
  .title {
    font-size: 40px;
    font-weight: 900;
    color: #ffffff;
  }
  .vendors {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
    .vendor {
      cursor: pointer;
      background: #30344B;
      padding: 12px 32px;
      border-radius: 24px;
      border: 1px solid #4A4C64;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.1s ease-in-out;
      &:hover {
        transition: all 0.1s ease-in-out;
        background: #3D415C;
      }
    }
  }
}