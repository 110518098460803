.app-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  z-index: 9999;
  padding: 23px 0;
  opacity: 0;

  &.show {
    animation: opacityToShow 1s linear 1;
    animation-fill-mode: forwards;
    animation-delay: 1.2s;
  }

  .app-header-main-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .header-left-side {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 25px;

      &.isMobile {
        justify-content: space-between;
        width: 100%;
      }

      .brand {
        max-width: 90px;
        width: 100%;
      }

      .mode-switcher {
        max-width: 80px;
        border-radius: 24px;
        background: #303341;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 6px;
        flex: 1;
        cursor: pointer;

        &.light-mode {
          background: #CDCED8;
        }

        .mode {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 6px;

          &.active {
            background: #252733;
          }

          &.active-light {
            background: #FFF;
          }

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .header-actions {
      a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #FAFAFA;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
        padding: 10px 12px;
        border-radius: 3px;
        background: #34A853;

        span {
          display: block;
          margin-left: 9px;
        }
      }
    }
  }
}

@keyframes opacityToShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}