.carComp {
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #252733;
    padding: 87px 12px 53px 12px;
    &.lightMode {
      background-color: #fff;
    }
  }
  
  .aboutUs {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 900;
    &.lightAboutUs {
      color: #252733;
    }
  }
  
  .youtube {
    margin-top: 9px;
  }
  
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 6px;
    border: none;
    width: 80px;
    font-size: 8px;
    font-weight: 600;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #252733;
    &.lightBtn {
      color: #252733;
      background-color: #FFFFFF;
    }
  }
  
  .collapseWrapper {
    display: grid;
    gap: 7px;
    margin-top: 20px;
  }
  
  .collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 13px;
    background: #FFFFFF;
    border-radius: 3px 3px 0 0 !important;
    &.isOpen {
      border-radius: 3px !important;
    }
    &.lightCollapse {
      background: #F5F5F5;
    }
  }
  
  .content {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
  }

  .vendorsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    .title {
      font-size: 15px;
      font-weight: 900;
      color: #ffffff;
    }
    .vendors {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .vendor {
        cursor: pointer;
        width: 100%;
        background: #30344B;
        padding: 12px 32px;
        max-height: 80px;
        border-radius: 24px;
        border: 1px solid #4A4C64;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }